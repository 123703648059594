import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';
import QRCode from 'qrcode';

function LocationEdit() {
  const [activeTab, setActiveTab] = useState('createLocation');
  const [locations, setLocations] = useState([]);


  const [formData, setFormData] = useState({ name: '', isactive: 'true' });
  const [createMessage, setCreateMessage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const locationsPerPage = 12;
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [editingLocationData, setEditingLocationData] = useState({});
  const navigate = useNavigate();
  const [selectedLocations, setSelectedLocations] = useState([]);


  const fetchLocations = useCallback(async () => {
    try {
      const response = await API.get('/get_locations', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLocations(response.data.locations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setUpdateMessage('Error fetching locations.');
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    if (createMessage) {
      const timer = setTimeout(() => setCreateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [createMessage]);

  useEffect(() => {
    if (updateMessage) {
      const timer = setTimeout(() => setUpdateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [updateMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'isactive' ? value : value,
    }));
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/create_location', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setCreateMessage('Location added successfully.');
      setLocations((prevLocations) => [...prevLocations, response.data.location]);
      setFormData({ name: '', isactive: 'true' });
    } catch (error) {
      console.error('Error saving location:', error);
      setCreateMessage('Error saving location.');
    }
  };

  const startEditing = (location) => {
    setEditingLocationId(location.id);
    setEditingLocationData(location);
  };

  const handleSaveEdit = async () => {
    try {
      await API.put(`/update_location/${editingLocationId}`, editingLocationData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Güncellenen lokasyonu listede değiştir
      setLocations((prevLocations) =>
        prevLocations.map((loc) =>
          loc.id === editingLocationId ? { ...loc, ...editingLocationData } : loc
        )
      );
  
      setUpdateMessage('Lokasyon başarıyla güncellendi.');
      setEditingLocationId(null);
    } catch (error) {
      console.error('Lokasyon güncellenirken hata oluştu:', error);
      setUpdateMessage('Lokasyon güncellenirken hata oluştu.');
    }
  };
  

  const printSelectedQRCodes = async () => {
    try {
      const printWindow = window.open('', '_blank');
  
      printWindow.document.write(`
        <html>
        <head>
          <style>
            .qr-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: 10px; /* Aralığı daralttık */
              padding: 20px;
            }
            .qr-item {
              width: 29%;
              text-align: center;
              border: 1px solid black; /* Grid çizgilerini ekledik */
              padding: 10px;
              box-sizing: border-box;
              margin-bottom: 10px;
            }
            img {
              max-width: 100%;
            }
          </style>
        </head>
        <body>
          <div class="qr-container">
      `);
  
      for (const location of selectedLocations) {
        const qrCodeDataUrl = await QRCode.toDataURL(location.id);
        printWindow.document.write(`
          <div class="qr-item">
            <p style="font-size: 13px; font-weight: bold;">${location.name}</p>
            <img src="${qrCodeDataUrl}" />
          </div>
        `);
      }
  
      printWindow.document.write(`
          </div>
        </body>
        </html>
      `);
  
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    } catch (error) {
      console.error('Error generating QR codes:', error);
    }
  };
  
  


  const toggleLocationSelection = (location) => {
    setSelectedLocations((prevSelected) => {
      if (prevSelected.includes(location)) {
        return prevSelected.filter((item) => item.id !== location.id);
      } else {
        return [...prevSelected, location];
      }
    });
  };
  const handleCancelEdit = () => {
    setEditingLocationId(null);
    setEditingLocationData({});
  };
  

  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  ).slice((currentPage - 1) * locationsPerPage, currentPage * locationsPerPage);


  const paginate = (pageSetter, page, items, itemsPerPage) =>
    Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => pageSetter(index + 1)}
        style={styles.pageButton}
        className={page === index + 1 ? 'active' : ''}
      >
        {index + 1}
      </button>
    ));

  return (
    <div style={styles.container}>
      <div style={styles.cancelButtonContainer}>
        <button type="button" onClick={() => navigate('/home')} style={styles.cancelButton}>
          Ana Sayfa
        </button>
      </div>

      <div style={styles.tabHeader}>
        <button
          style={activeTab === 'createLocation' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('createLocation')}
        >
          Yeni Lokasyon
        </button>
        <button
          style={activeTab === 'existingLocations' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('existingLocations')}
        >
          Mevcut Lokasyonlar
        </button>
      </div>

      <div style={styles.tabContent}>
        {activeTab === 'createLocation' && (
          <div>
            {createMessage && <p style={styles.message}>{createMessage}</p>}

            <form onSubmit={handleAddLocation} style={styles.form} autoComplete="off">
              <div style={styles.gridContainer}>
                <input
                  type="text"
                  name="name"
                  placeholder="Lokasyon Adı"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  style={styles.input}
                  autoComplete="off"
                />
                <select
                  name="isactive"
                  value={formData.isactive}
                  onChange={handleInputChange}
                  style={styles.input}
                >
                  <option value="true">Aktif</option>

                </select>
              </div>
              <div style={styles.buttonContainer}>
                <button type="submit" style={styles.saveButton}>
                  Kaydet
                </button>
              </div>
            </form>
          </div>
        )}

        {activeTab === 'existingLocations' && (
          <div>
            {updateMessage && <p style={styles.message}>{updateMessage}</p>}
            <button
              onClick={printSelectedQRCodes}
              style={styles.printButton}
              disabled={selectedLocations.length === 0}
            >
              Seçilenleri Yazdır
            </button>
            <input
              type="text"
              placeholder="Ara"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
              autoComplete="off"
            />
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>Adı</th>
                  <th style={styles.th}>Aktif</th>
                  <th style={styles.th}></th>
                </tr>
              </thead>
              <tbody>
  {filteredLocations.map((location) => (
    <tr key={location.id}>
      <td style={styles.td}>
        <input
          type="checkbox"
          checked={selectedLocations.includes(location)}
          onChange={() => toggleLocationSelection(location)}
        />
      </td>

      {editingLocationId === location.id ? (
        <>
          <td style={styles.td}>
            <input
              type="text"
              value={editingLocationData.name}
              onChange={(e) =>
                setEditingLocationData({ ...editingLocationData, name: e.target.value })
              }
              style={styles.input}
            />
          </td>
          <td style={styles.td}>
            <select
              value={editingLocationData.isactive}
              onChange={(e) =>
                setEditingLocationData({ ...editingLocationData, isactive: e.target.value })
              }
              style={styles.input}
            >
              <option value="true">Aktif</option>
              <option value="false">Pasif</option>
            </select>
          </td>
          <td style={styles.td}>
            <button onClick={handleSaveEdit} style={styles.saveButton}>
              Kaydet
            </button>
            <button onClick={handleCancelEdit} style={styles.cancelButton}>
              İptal
            </button>
          </td>
        </>
      ) : (
        <>
          <td style={styles.td}>{location.name}</td>
          <td style={styles.td}>{location.isactive ? 'Aktif' : 'Pasif'}</td>
          <td style={styles.td}>
            <button onClick={() => startEditing(location)} style={styles.editButton}>
              Düzenle
            </button>
          </td>
        </>
      )}
    </tr>
  ))}
</tbody>

            </table>
            <div style={styles.pagination}>
              {paginate(setCurrentPage, currentPage, locations, locationsPerPage)}
            </div>


          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  cancelButtonContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '10px 20px',
    fontSize: '10px',
    color: '#fff',
    backgroundColor: '#f44336',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  tabHeader: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    border: 'none',
    fontSize: '10px',
  },
  activeTab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #007bff',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  tabContent: {
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
  },
  input: {
    padding: '12px',
    fontSize: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    width: '90%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  saveButton: {
    padding: '12px 20px',
    fontSize: '10px',
    color: '#fff',
    backgroundColor: '#4CAF50',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginRight: '5px',
  },
  searchInput: {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '10px',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  editButton: {
    padding: '8px 12px',
    fontSize: '10px',
    color: '#fff',
    backgroundColor: '#2196F3',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  printButton: {
    padding: '8px 12px',
    fontSize: '10px',
    color: '#fff',
    backgroundColor: '#FFA500',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  message: {
    marginBottom: '20px',
    fontSize: '10px',
    color: '#4CAF50',
    textAlign: 'center',
  },

  pagination: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  pageButton: {
    padding: '8px 12px',
    fontSize: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },

};

export default LocationEdit;
